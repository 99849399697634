import React from 'react'
import Helmet from 'react-helmet'
import Link from 'gatsby-link'
import { graphql } from 'gatsby'

import Layout from "../components/layout"

export default function Template({ data, pageContext }) {
  const { markdownRemark: post } = data
  const { prev, next } = pageContext

  return (
    <Layout>
      <section className="section">
        <Helmet title={`Blog | ${post.frontmatter.title}`} />
        <div className="top">
          <h1>{post.frontmatter.title}</h1>
          <small>{post.frontmatter.date}</small>
        </div>
        <section className="content blog">
          <div dangerouslySetInnerHTML={{ __html: post.html }} />
        </section>
        <div className="read-next">
          {prev && (
            <Link className="previous" to={prev.fields.slug}>
              <p>Read Previous</p>
              {prev.frontmatter.title}
            </Link>
          )}
          {next && (
            <Link className="next" to={next.fields.slug}>
              <p>Read Next</p>
              {next.frontmatter.title}
            </Link>
          )}
        </div>
      </section>
    </Layout>
  )
}

/* eslint no-undef: 'off' */
export const pageQuery = graphql`
  query BlogPostByPath($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
      }
      fields {
        slug
      }
    }
  }
`
